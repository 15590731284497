import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-6">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to Idaho Desktop Calendars. These terms and conditions outline the rules and regulations for the use of our website.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing this website and purchasing our 12-month 2025 Idaho Desktop calendar, you accept and agree to be bound by these terms and conditions. If you do not agree with these terms, please do not use our website.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Purchases</h2>
      <p className="mb-4">
        When you place an order for the Idaho Desktop Calendar, you agree to provide accurate payment and shipping information. Once payment is confirmed, you will receive an email with details of your order.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Shipping</h2>
      <p className="mb-4">
        Orders will be shipped starting in November 2024. Shipping times may vary depending on location and delivery service. Idaho Desktop Calendars is not responsible for shipping delays caused by third-party carriers.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Returns and Refunds</h2>
      <p className="mb-4">
        If you are not satisfied with your purchase, you may return the calendar within 30 days of receipt for a refund, provided the product is unused and in its original packaging. The customer is responsible for return shipping costs.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes to Terms</h2>
      <p className="mb-4">
        Idaho Desktop Calendars reserves the right to modify or replace these Terms and Conditions at any time. Your continued use of the website after any such changes will constitute your acceptance of the new terms.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Information</h2>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:hello@travisleslie.com" className="text-blue-500 hover:underline">hello@travisleslie.com</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
