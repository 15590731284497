import React from "react";
import TravisImage from '../assets/images/travisprofile.png'; 
import TravisSocial from '../assets/images/TL_Social_Icon.png';

const AboutMe = () => {
  return (
    <div className="bg-gray-100 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4 items-center">
          <div className="flex justify-center lg:justify-start">
            <img
              className="rounded-full w-64 h-64 shadow-lg"
              src={TravisImage} 
              alt="Travis Leslie"
            />
          </div>
          <div className="relative">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-8">About Me</h1>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Travis Leslie
            </h2>
            <p className="text-base underline text-gray-700 mb-4">Idaho Native</p>
            <p className="text-lg text-gray-600 mb-4">
              I'm born and raised in Boise, Idaho, and like many of you, I share a passion for this amazing state and the great outdoors. Idaho is full of hidden gems, some well-known, others off the beaten path, but all unique.
            </p>
            <p className="text-lg text-gray-600 mb-4">
              I created this desktop calendar to showcase Idaho's incredible scenery. It was hard to choose only 12 images! The calendar base is made from 100% real walnut wood, and the prints are 4x6 premium card stock with an anti-glare finish. The colors really "pop," bringing the beauty of Idaho into your space.
            </p>
            <p className="text-lg text-gray-600 mb-4">
              I hope you enjoy the calendar on your desk at home, in the office, kitchen, or wherever it fits. Each month will leave you inspired by the breathtaking landscapes our great state has to offer.
            </p>
            <p className="text-lg text-zinc-800">
              Thanks for your time.
            </p>
            <div className="flex justify-center mt-6">
              <img
                className="rounded-full w-12 h-12 shadow-lg"
                src={TravisSocial}
                alt="Travis Social Icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
