import React, { useState, useEffect, useRef } from 'react';

const PreOrderCount = ({ targetPreOrders }) => {
  // State for current count
  const [preOrderCount, setPreOrderCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false); // Trigger for starting the count
  const countRef = useRef(null); // Ref for the count element

  // Intersection observer to detect when the component is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCounting(true); // Start counting when in view
            observer.disconnect(); // Disconnect observer once counting starts
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the component is in view
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => observer.disconnect(); // Clean up observer on unmount
  }, []);

  // Effect to increment the count when the user scrolls the component into view
  useEffect(() => {
    if (startCounting) {
      const increment = Math.ceil(targetPreOrders / 100); // Increment step size
      const interval = setInterval(() => {
        setPreOrderCount((prevCount) => {
          if (prevCount < targetPreOrders) {
            return prevCount + increment;
          } else {
            clearInterval(interval);
            return targetPreOrders;
          }
        });
      }, 20); // Update the count every 20ms

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [startCounting, targetPreOrders]);

  return (
    <span ref={countRef}>
      {preOrderCount}+
    </span>
  );
};

export default PreOrderCount;
