import React, { useState } from 'react';
import heroImage1 from "../assets/images/Hero_Bruneausanddnes.png"; 
import heroImage2 from "../assets/images/Hero_HellsCanyon.png"; 
import heroImage3 from "../assets/images/Hero_Lostriverrange.png"; 
import heroImage4 from "../assets/images/Hero_Payetteriver.png";
import heroImage5 from "../assets/images/Hero_TetonValley.png"; 
import calendarlogo from "../assets/images/IdahoCalendarLogo25.png";
import whitelogo from "../assets/images/IdahoCalendarLogo25white.png";
import leafshadow from "../assets/icons/leafshadow.svg"; 
import greencheck from "../assets/icons/greencheck.svg"; 

const ThankYou = () => {
  // State to handle copy link feedback
  const [copied, setCopied] = useState(false);

  // The referral link (you can adjust this with a real referral link)
  const referralLink = "https://calendar.travisleslie.com/";

  // Function to copy the link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset the copied state after 3 seconds
  };

  return (
    <>
      <section className="relative overflow-hidden h-screen bg-white py-16 flex items-center">
        <div
          className="relative z-10 flex flex-col items-center justify-start max-w-lg mx-auto px-4 sm:px-6 lg:px-8"
          style={{ marginLeft: "200px" }}
        >
          {/* Green Checkmark SVG */}
          <img
            src={greencheck}
            alt="Green Checkmark"
            className="w-24 h-24 mb-4"
          />

          {/* Calendar Logo */}
          <img
            src={calendarlogo}
            alt="Calendar Logo"
            className="w-auto h-auto"
          />

          {/* Text section */}
          <div className="mt-12 flex flex-col items-center text-center">
            <p className="text-zinc-800 bg-yellow-300 py-4 text-4xl uppercase font-bold mb-12">
              Thank you for your order!
            </p>
            <p className="text-black text-lg">
              YOU WILL RECEIVE A SHIPPING NOTIFICATION VIA EMAIL WHEN YOUR CALENDAR SHIPS
            </p>
            <p className="mt-4 bg-gray-200 py-2 px-8 rounded-md text-gray-700">
              SHIPPING NOVEMBER 2024
            </p>

            {/* Refer a Friend Section */}
            <div className="mt-12">
              <p className="text-xl font-semibold mb-4 text-gray-900">
                Refer a friend and share the love!
              </p>
              <div className="flex flex-col items-center">
                {/* Copy Link Button */}
                <button
                  onClick={handleCopyLink}
                  className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700 transition duration-300 mb-2"
                >
                  Copy Referral Link
                </button>

                {/* Feedback text */}
                {copied && (
                  <p className="text-green-500 font-semibold mt-2">
                    Link copied to clipboard!
                  </p>
                )}

                {/* Display the referral link */}
                <p className="text-sm text-gray-600 mt-2">{referralLink}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Image collage */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="absolute top-[-150px] right-[350px] flex flex-col space-y-4 transform rotate-[-30deg]">
            <img
              src={heroImage1}
              alt="Hero 1"
              className="w-[300px] h-auto object-cover shadow-lg"
            />
            <img
              src={heroImage2}
              alt="Hero 2"
              className="w-[300px] h-auto object-cover shadow-lg"
            />
            <img
              src={heroImage3}
              alt="Hero 3"
              className="w-[300px] h-auto object-cover shadow-lg"
            />
          </div>
          <div className="absolute top-[-90px] right-[20px] flex flex-col space-y-4 transform rotate-[-30deg]">
            <img
              src={heroImage4}
              alt="Hero 4"
              className="w-[300px] h-auto object-cover shadow-lg"
            />
            <img
              src={heroImage5}
              alt="Hero 5"
              className="w-[300px] h-auto object-cover shadow-lg"
            />
          </div>
        </div>

        {/* Leaf Shadow */}
        <img
          src={leafshadow}
          alt="Leaf Shadow"
          className="absolute top-0 left-0 w-[400px] h-auto z-0"
        />
      </section>



    </>
  );
};

export default ThankYou;
