import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-6">Privacy Policy</h1>
      <p className="mb-4">
        At Idaho Desktop Calendars, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal information.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Information We Collect</h2>
      <p className="mb-4">
        We collect personal information that you provide to us when you place an order, such as your name, email address, shipping address, and payment details. We do not store any payment information on our servers.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">How We Use Your Information</h2>
      <p className="mb-4">
        We use the information you provide to process your order, send you updates about your order, and deliver the Idaho Desktop Calendar to your shipping address. We may also use your email address to send you promotional materials, but you can opt out at any time.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Cookies</h2>
      <p className="mb-4">
        Our website may use cookies to enhance your browsing experience. You can disable cookies in your browser settings, but this may affect the functionality of our website.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Security</h2>
      <p className="mb-4">
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Third-Party Services</h2>
      <p className="mb-4">
        We may use third-party services to process payments or handle shipping. These third parties only have access to the personal information necessary to perform their services.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes to this Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the last update will be indicated at the bottom of this policy.
      </p>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Information</h2>
      <p>
        If you have any questions about our Privacy Policy, please contact us at <a href="mailto:hello@travisleslie.com" className="text-blue-500 hover:underline">hello@travisleslie.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
