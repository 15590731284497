import React, { useState, useEffect } from 'react';
import heroImage1 from "../assets/images/calendar/bruneau.png"; 
import heroImage2 from "../assets/images/calendar/hells.png"; 
import heroImage3 from "../assets/images/calendar/lost.png"; 
import heroImage4 from "../assets/images/calendar/payette.png";
import heroImage5 from "../assets/images/calendar/teton.png"; 
import sectiononeimage1 from "../assets/images/calendar/lost.png"; 
import sectiononeimage2 from "../assets/images/calendar/shoshone.png"; 
import sectiononeimage3 from "../assets/images/calendar/rollinghills.png"; 
import sectiontwoimage1 from "../assets/images/calendar/coeur.png"; 
import sectiontwoimage2 from "../assets/images/calendar/craters.png"; 
import sectiontwoimage3 from "../assets/images/calendar/hells.png"; 
import sectionthreeimage1 from "../assets/images/calendar/sunvalley.png"; 
import sectionthreeimage2 from "../assets/images/calendar/sawtooth.png"; 
import sectionthreeimage3 from "../assets/images/calendar/cityofrocks.png"; 
import calendarlogo from "../assets/images/IdahoCalendarLogo25.png";
import leafshadow from "../assets/icons/leafshadow.svg"; 
import largeblocksection1 from "../assets/images/idahocalendarblock1.webp";
import largeblocksection2 from "../assets/images/final-idahocalendarblock2.webp";
import calendarshadow from "../assets/icons/calendarshadow.svg";
import icon1 from "../assets/icons/thickpaper.svg";
import icon2 from "../assets/icons/realwood.svg";
import icon3 from "../assets/icons/shinefree.svg";
import icon4 from "../assets/icons/silkysmooth.svg";
import icon5 from "../assets/icons/size.svg";
import icon6 from "../assets/icons/calendar.svg";
import calendarImage1 from "../assets/images/Hero_TetonValley.png";
import calendarImage2 from "../assets/images/Hero_Bruneausanddnes.png";
import stickerImage from "../assets/images/idahoismyhome.png";
import whitelogo from "../assets/images/IdahoCalendarLogo25white.png";
import greencheck from "../assets/icons/greencheck.svg";
import PreOrderCount from '../components/PreOrderCount';
import featured1 from '../assets/images/calendar/teton.png';
import featured2 from '../assets/images/calendar/bruneau.png';
import featured3 from '../assets/images/calendar/lost.png';
import featured4 from '../assets/images/calendar/payette.png';
import featured5 from '../assets/images/calendar/rollinghills.png';
import featured6 from '../assets/images/calendar/coeur.png';
import featured7 from '../assets/images/calendar/craters.png';
import featured8 from '../assets/images/calendar/hells.png';
import featured9 from '../assets/images/calendar/sawtooth.png';
import featured10 from '../assets/images/calendar/sunvalley.png';
import featured11 from '../assets/images/calendar/cityofrocks.png';
import featured12 from '../assets/images/calendar/shoshone.png';
import TestimonialSection from '../components/Testimonails';
import AboutMe from '../components/AboutMe';


import stand1 from '../assets/images/cratersstand.png'; 
import stand2 from '../assets/images/tetonstand.png';
import stand3 from '../assets/images/sawtoothstand.png';
import stand4 from '../assets/images/shoshonestand.png';
import stand5 from '../assets/images/sunvalleystand.png';
import stand6 from '../assets/images/cityofrocksstand.png';

import mobile1 from '../assets/images/desk/Bruneau_desk.jpg'; 
import mobile2 from '../assets/images/desk/coeur_desk.jpg';
import mobile3 from '../assets/images/desk/craters_desk.jpg';
import mobile4 from '../assets/images/desk/Cityofrock_desk.jpg';
import mobile5 from '../assets/images/desk/Hells_desk.jpg';
import mobile6 from '../assets/images/desk/sawtooth_desk.jpg';
import mobile7 from '../assets/images/desk/shoshone_desk.jpg';
import mobile8 from '../assets/images/desk/sunvalley_desk.jpg';
import mobile9 from '../assets/images/desk/rollinhills_desk.jpg';
import mobile10 from '../assets/images/desk/teton_desk.jpg';
import mobile11 from '../assets/images/desk/lost_desk.jpg';
import mobile12 from '../assets/images/desk/payette_desk.jpg';

const hellsstandImages = [stand1, stand2, stand3, stand4, stand5, stand6];
const mobileImages = [mobile1, mobile2, mobile3, mobile4, mobile5, mobile6, mobile7, mobile8, mobile9, mobile10, mobile11, mobile12];


const Calendars = () => {
  // Array of images for hellsstand
  const hellsstandImages = [stand1, stand2, stand3, stand4, stand5, stand6];
  
  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Change the image every 1 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === hellsstandImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [hellsstandImages.length]);

  return (
    <div className="min-h-screen bg-white md:bg-gray-100">
      <main className="bg-white">
      <section className="relative overflow-hidden bg-white md:py-12 flex flex-col justify-center md:justify-center items-center h-screen">
    {/* Dynamic Image for Mobile (visible above the logo on mobile) */}
    <div className="relative z-10 mb-12 md:mb-6 sm:hidden">
      <img
        src={mobileImages[currentImageIndex]} // Show mobile image for mobile view
        alt="Hells Stand Mobile"
        className="w-full md:w-[124px] h-auto mx-auto"
      />
    </div>

  <div
  className="relative z-10 flex flex-col items-center justify-center max-w-lg mx-auto px-8 sm:px-6 lg:px-8 lg:ml-[200px]"
>

    {/* Calendar Logo */}
    <img
      src={calendarlogo}
      alt="Calendar Logo"
      className="w-[200px] md:w-auto md:h-auto"
    />

    <div className="mt-6 flex flex-col items-center">
      {/* Description */}
      <p className="text-lg md:text-2xl text-center text-[#1e1e1e] text-zinc-700 leading-5 md:leading-10 font-bold mt-1 mb-2 md:mt-4 md:mb-8">
        Capture the Spirit of Idaho with this 4x6 Premium Desk Calendar
      </p>

      {/* Pre-Order Button */}
      <a 
  href="https://buy.stripe.com/28o01dehl4eZ3BK4gn" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="bg-red-500 w-full text-white text-base md:text-lg font-bold px-8 md:px-24 py-4 mb-2 hover:bg-orange-600 text-center block"
>
  PRE-ORDER <span className="line-through">$32</span> $24
</a>

      {/* Flex container for Limited Time and 25% OFF messages */}
      <div className="flex space-x-4 mt-2">
        <div className="text-base md;text-lg text-zinc-700 bg-gray-100 py-1 px-4 rounded-md font-bold">
          Limited Time Offer
        </div>
        <div className="text-base md:text-lg text-green-500 bg-green-100 py-1 px-4 rounded-md font-bold">
          25% OFF
        </div>
      </div>

      {/* Shipping Information */}
      <div className="flex space-x-4 mt-4">
        <div className="text-sm text-zinc-800 bg-yellow-300 py-2 px-8 rounded-md font-regular">
          Shipping November 2024
        </div>
      </div>
    </div>
  </div>

  {/* Hero Images (hidden on mobile, visible on desktop) */}
  <div className="absolute inset-0 hidden sm:flex justify-center items-center">
    <div className="absolute top-[-150px] right-[350px] flex flex-col space-y-4 transform rotate-[-30deg]">
      <img
        src={heroImage1}
        alt="Hero 1"
        className="w-[300px] h-auto object-cover shadow-lg"
      />
      <img
        src={heroImage2}
        alt="Hero 2"
        className="w-[300px] h-auto object-cover shadow-lg"
      />
      <img
        src={heroImage3}
        alt="Hero 3"
        className="w-[300px] h-auto object-cover shadow-lg"
      />
    </div>
    <div className="absolute top-[-90px] right-[20px] flex flex-col space-y-4 transform rotate-[-30deg]">
      <img
        src={heroImage4}
        alt="Hero 4"
        className="w-[300px] h-auto object-cover shadow-lg"
      />
      <img
        src={heroImage5}
        alt="Hero 5"
        className="w-[300px] h-auto object-cover shadow-lg"
      />
    </div>
  </div>

  {/* Dynamic Hellsstand Image (for desktop only) */}
  <img
    src={hellsstandImages[currentImageIndex]}
    alt="Hells Stand"
    className="absolute bottom-[200px] left-[750px] w-[150px] h-auto z-0 hidden sm:block"
  />

  {/* Leaf Shadow (hidden on mobile, shown on desktop) */}
  <img
    src={leafshadow}
    alt="Leaf Shadow"
    className="absolute top-0 left-0 w-[500px] h-auto z-0 opacity-50 hidden sm:block"
  />
</section>


        <section className="py-16 bg-[#E0E0E0]">
          <div className="max-w-7xl mx-auto mt-12 px-4 sm:px-6 lg:px-24">
            <div className="text-start">
              <h2 className="text-4xl md:text-5xl uppercase font-extrabold text-gray-900">
                Embrace Nature's Artistry
              </h2>
              <p className="mt-8 text-lg font-regular leading-8 text-gray-600 w-full md:w-[700px]">
                Bring the Beauty of Idaho into Your Daily Life with Our Artistic
                Desk Calendar. Each of the 12 handcrafted illustrations immerses
                you in the majestic natural wonders of Idaho, transforming every
                month into an expedition through the state's unparalleled
                landscapes.
              </p>
            </div>
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-24 gap-8">
              <img
                src={sectiononeimage1}
                alt="Content 1"
                className="w-full h-auto object-cover"
              />
              <img
                src={sectiononeimage2}
                alt="Content 2"
                className="w-full h-auto object-cover"
              />
              <img
                src={sectiononeimage3}
                alt="Content 3"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </section>
        <section className="bg-white items-center">
          <div className="w-full">
            <img
              src={largeblocksection1}
              className="w-full h-auto object-cover"
              alt="Large Block Section"
            />
          </div>
        </section>

        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto mt-12 px-4 sm:px-6 lg:px-24">
            <div className="text-start">
              <h2 className="text-4xl md:text-5xl uppercase font-extrabold text-gray-900">
                A Standout Piece{" "}
              </h2>
              <p className="mt-8 text-lg font-regular leading-8 text-gray-600 w-full md:w-[700px]">
                More than a simple calendar, this is a statement of elegance and
                artistry. Displayed on a sophisticated real wood stand, it's not
                just about keeping track of dates—it's about adding a piece of
                Idaho's grandeur to your personal space, whether at work or in
                the comfort of your home.{" "}
              </p>
            </div>
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-24 gap-8">
              <img
                src={sectiontwoimage1}
                alt="Content 1"
                className="w-full h-auto object-cover"
              />
              <img
                src={sectiontwoimage2}
                alt="Content 2"
                className="w-full h-auto object-cover"
              />
              <img
                src={sectiontwoimage3}
                alt="Content 3"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </section>

    
        <section className="relative items-center ">
  <div className="absolute top-0 left-0 w-full h-[400px] bg-white"></div>
  <div className="absolute bottom-0 left-0 w-full h-[400px] bg-[#AFAFAF]"></div>
  <div className="relative flex items-center justify-center w-full  h-auto py-16">
      
      <img
        src={largeblocksection2}
        className="relative w-auto h-full"
        alt="Large Block Section"
      />
    </div>
</section>



        <section className="relative py-24 bg-[#AFAFAF]">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-24">
        <div className="relative z-10 text-start">
          <h2 className="text-4xl md:text-5xl uppercase font-extrabold text-gray-900">
            Inspiration Every Day
          </h2>
          <p className="mt-8 text-lg font-regular leading-8 text-gray-600 w-full md:w-[700px]">
            Let each day be a reminder of the serene beauty and endless
            inspiration found in Idaho's great outdoors. These illustrations
            serve not just as beautiful artwork but as a daily nudge to
            explore the natural wonders that lie beyond your doorstep.
          </p>
        </div>
        <div className="relative z-10 mt-12 mb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <img src={sectionthreeimage1} alt="Content 1" className="w-full h-auto object-cover" />
          <img src={sectionthreeimage2} alt="Content 2" className="w-full h-auto object-cover" />
          <img src={sectionthreeimage3} alt="Content 3" className="w-full h-auto object-cover" />
        </div>
      </div>
    </section>

    <section className="py-4 md:py-16 mt-1 md:mt-12 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="hidden md:flex flex-col items-center justify-center">
      <div className="flex flex-col md:flex-row md:space-x-8 items-start justify-center gap-36">
        <img
          src={calendarImage1}
          alt="Teton Valley"
          className="w-[400px] h-auto object-cover shadow-lg mb-8 md:mb-0"
        />
        <img
          src={calendarImage2}
          alt="Bruneau Sand Dunes"
          className="w-[400px] h-auto object-cover shadow-lg"
        />
      </div>
      <div className="mt-24 w-full max-w-5xl text-left">
        <h2 className="text-4xl md:text-5xl uppercase font-extrabold text-gray-900">
          The Perfect Gift
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Ideal for gifting or as a treasured keepsake, this desk calendar
          stands as a daily escape to Idaho's most scenic views. It's an
          invitation to witness the splendor of nature every day, making it
          a thoughtful and meaningful gift for anyone who appreciates the
          great outdoors.
        </p>
      </div>
    </div>
  </div>
</section>




<section className="py-2 md:py-16 mt-2 mb-12 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-36">

    {/* Featuring Section */}
    <h4 className="text-2xl uppercase font-extrabold text-gray-900 mb-6">
      Featuring:
    </h4>
    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-24 text-sm font-semibold text-gray-700">
      <li className="flex items-center space-x-3">
        <img src={featured1} alt="Teton Valley" className="w-8 h-12 object-cover rounded-md" />
        <span>Teton Valley</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured2} alt="Bruneau Sand Dunes" className="w-8 h-12 object-cover rounded-md" />
        <span>Bruneau Sand Dunes</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured3} alt="Lost River Range" className="w-8 h-12 object-cover rounded-md" />
        <span>Lost River Range</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured4} alt="Payette River" className="w-8 h-12 object-cover rounded-md" />
        <span>Payette River</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured5} alt="Rolling Hills of Palouse" className="w-8 h-12 object-cover rounded-md" />
        <span>Rolling Hills of Palouse</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured6} alt="Coeur D'Alene" className="w-8 h-12 object-cover rounded-md" />
        <span>Coeur D'Alene</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured7} alt="Craters of the Moon" className="w-8 h-12 object-cover rounded-md" />
        <span>Craters of the Moon</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured8} alt="Hells Canyon" className="w-8 h-12 object-cover rounded-md" />
        <span>Hells Canyon</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured9} alt="Sawtooth Mountain Range" className="w-8 h-12 object-cover rounded-md" />
        <span>Sawtooth Mountain Range</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured10} alt="Sun Valley" className="w-8 h-12 object-cover rounded-md" />
        <span>Sun Valley</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured11} alt="City Of Rocks" className="w-8 h-12 object-cover rounded-md" />
        <span>City Of Rocks</span>
      </li>
      <li className="flex items-center space-x-3">
        <img src={featured12} alt="ShoShone Falls" className="w-8 h-12 object-cover rounded-md" />
        <span>Shoshone Falls</span>
      </li>
    </ul>

    <h2 className="text-2xl uppercase font-extrabold text-gray-900 mb-12">
      The Details
    </h2>
    {/* Icon Section */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-0 gap-y-24 text-center">
      <div className="flex flex-col items-center">
        <img src={icon5} alt="4x6 Premium Print" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold uppercase text-gray-900">4" x 6" Premium Print</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={icon6} alt="12 Month Calendar" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold uppercase text-gray-900">2025 12 Month Calendar</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={icon1} alt="Ultra Durable Thick Paper" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold text-gray-900">ULTRA DURABLE THICK PAPER</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={icon2} alt="100% Real Wood Stand" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold uppercase text-gray-900">100% REAL Walnut WOOD STAND</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={icon3} alt="Shine-Free No Glare Print" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold text-gray-900">SHINE-FREE NO GLARE PRINT</p>
      </div>
      <div className="flex flex-col items-center">
        <img src={icon4} alt="Silky Smooth Finish" className="w-12 h-12 md:w-20 md:h-20 mb-4" />
        <p className="text-base md:text-2xl font-extrabold text-gray-900">SILKY SMOOTH FINISH</p>
      </div>
    </div>
  </div>
</section>

<TestimonialSection />

    <section className="py-16 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="relative z-10 flex flex-col items-center justify-center max-w-lg mx-auto text-center">
      <img
        src={calendarlogo}
        alt="Calendar Logo"
        className="w-auto h-auto mb-6"
      />
      <a 
  href="https://buy.stripe.com/28o01dehl4eZ3BK4gn" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="bg-red-500 w-full text-white text-base md:text-lg font-bold px-8 md:px-24 py-4 mb-2 hover:bg-orange-600 text-center block"
>
  PRE-ORDER <span className="line-through">$32</span> $24
</a>
      <div className="flex items-center justify-between mb-4 w-full mt-4 px-8 md:px-24">
      <p className="text-gray-700 font-bold">
              Over <PreOrderCount targetPreOrders={100} /> Pre-orders
            </p>        <div className="text-base md:text-lg text-green-500 bg-green-100 py-1 px-4 rounded-md font-bold">
          25% OFF
        </div>
      </div>
      <p className="mt-4 bg-gray-200 py-2 px-4 md:px-8 rounded-md text-gray-700">
        SHIPPING NOVEMBER 2024
      </p>
    </div>
  </div>
</section>

<section className="py-2 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center text-center">
    {/* Green Checkmark SVG */}
    <img
      src={greencheck}
      alt="Green Checkmark"
      className="w-12 h-12 mb-4"  // Adjust size as necessary
    />
    
    {/* Text above the image */}
    <div className="text-center mb-4">
      <p className="text-3xl mb-4 font-bold uppercase text-gray-900">Free Sticker</p>
      <p className="text-xl text-gray-600">$4.99 Value</p>
    </div>

    {/* Sticker Image */}
    <img
      src={stickerImage}
      alt="Idaho Sticker"
      className="w-[200px] h-auto mb-24"
    />
  </div>
</section>
<AboutMe />

    
      </main>
    </div>
  );
};

export default Calendars;
