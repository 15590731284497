import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you're using react-router for internal links
import whitelogo from '../assets/images/IdahoCalendarLogo25white.png'; // Adjust the path as necessary

const Footer = () => {
  return (
    <footer className="py-8 bg-gray-900 text-white flex flex-col lg:flex-row lg:items-center lg:justify-between">
      
      {/* Centered logo and contact information */}
      <div className="flex flex-col items-center text-center lg:items-center lg:text-center lg:flex-grow">
        {/* Logo linking back to homepage */}
        <a href="/" className="mb-4">
          <img
            src={whitelogo}
            alt="Calendar Logo"
            className="w-24 h-auto"
          />
        </a>
        <p className="text-sm">&copy; 2024 Idaho Calendars. All rights reserved.</p>
        <p className="text-sm mt-2">Contact Us: <a href="mailto:hello@travisleslie.com" className="hover:underline">hello@travisleslie.com</a></p>
      </div>
      
      {/* Links for Privacy Policy and Terms (aligned right on desktop, stacked on mobile) */}
      <div className="flex flex-col lg:flex-row items-center space-y-2 mr-12 lg:space-y-0 lg:space-x-8 lg:ml-auto">
        <Link to="/privacy-policy" className="text-sm hover:underline">
          Privacy Policy
        </Link>
        <Link to="/terms-and-conditions" className="text-sm hover:underline">
          Terms and Conditions
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
