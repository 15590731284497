import React from 'react';
import heroImage1 from "../assets/images/Hero_Bruneausanddnes.png"; 
import heroImage2 from "../assets/images/Hero_HellsCanyon.png"; 
import heroImage3 from "../assets/images/Hero_Lostriverrange.png"; 
import heroImage4 from "../assets/images/Hero_Payetteriver.png";
import heroImage5 from "../assets/images/Hero_TetonValley.png"; 
import calendarlogo from "../assets/images/IdahoCalendarLogo25.png";
import whitelogo from "../assets/images/IdahoCalendarLogo25white.png";
import leafshadow from "../assets/icons/leafshadow.svg"; 
import icon1 from "../assets/icons/thickpaper.svg";
import icon2 from "../assets/icons/realwood.svg";
import icon3 from "../assets/icons/shinefree.svg";
import icon4 from "../assets/icons/silkysmooth.svg";
import icon5 from "../assets/icons/size.svg";
import icon6 from "../assets/icons/calendar.svg";

const Cancel = () => {
    return (
      <>
        {/* First section with images and calendar details */}
        <section className="relative overflow-hidden h-screen bg-white py-16 flex items-center">
          <div
            className="relative z-10 flex flex-col items-center justify-start max-w-lg mx-auto px-4 sm:px-6 lg:px-8"
            style={{ marginLeft: "200px" }}
          >
            <img
              src={calendarlogo}
              alt="Calendar Logo"
              className="w-auto h-auto"
            />
            <div className="mt-12 text-lg text-black bg-yellow-300 py-1 px-4 rounded-md font-bold">
                LIMITED TIME OFFER!
            </div>
            <div className="mt-6 flex flex-col items-center">
            <a 
  href="https://buy.stripe.com/14k8xJ0qv4eZ1tCaEM" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="bg-red-500 w-full text-white text-base md:text-lg font-bold px-8 md:px-24 py-4 mb-2 hover:bg-orange-600 text-center block"
>
  PRE-ORDER <span className="line-through">$32</span> $24
</a>
              <div className="mt-2 text-lg text-green-500 bg-green-100 py-1 px-4 rounded-md font-bold">
                30% OFF
              </div>
              <p className="mt-12 bg-gray-200 py-2 px-8 rounded-md text-gray-700">
                SHIPPING NOVEMBER 2024
              </p>
            </div>
          </div>
          
          {/* Image collage */}
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="absolute top-[-150px] right-[350px] flex flex-col space-y-4 transform rotate-[-30deg]">
              <img
                src={heroImage1}
                alt="Hero 1"
                className="w-[300px] h-auto object-cover shadow-lg"
              />
              <img
                src={heroImage2}
                alt="Hero 2"
                className="w-[300px] h-auto object-cover shadow-lg"
              />
              <img
                src={heroImage3}
                alt="Hero 3"
                className="w-[300px] h-auto object-cover shadow-lg"
              />
            </div>
            <div className="absolute top-[-90px] right-[20px] flex flex-col space-y-4 transform rotate-[-30deg]">
              <img
                src={heroImage4}
                alt="Hero 4"
                className=" w-[300px] h-auto object-cover shadow-lg "
              />
              <img
                src={heroImage5}
                alt="Hero 5"
                className=" w-[300px] h-auto object-cover shadow-lg "
              />
            </div>
          </div>
          <img
            src={leafshadow}
            alt="Leaf Shadow"
            className="absolute top-0 left-0 w-[500px] h-auto z-0"
          />
        </section>

        <section className="py-16 mt-12 mb-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-36">
      <h2 className="text-4xl uppercase font-extrabold text-gray-900 mb-24">
          The Details
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-0 gap-y-24 text-center">
        <div className="flex flex-col items-center">
            <img src={icon5} alt="Ultra Durable Thick Paper" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold uppercase text-gray-900">4" x 6" Premium Print</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={icon6} alt="Ultra Durable Thick Paper" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold uppercase text-gray-900">2025 12 Month Calendar</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={icon1} alt="Ultra Durable Thick Paper" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold text-gray-900">ULTRA DURABLE THICK PAPER</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={icon2} alt="100% Real Wood Stand" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold uppercase text-gray-900">100% REAL Walnut WOOD STAND</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={icon3} alt="Shine-Free No Glare Print" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold text-gray-900">SHINE-FREE NO GLARE PRINT</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={icon4} alt="Silky Smooth Finish" className="w-20 h-20 mb-4" />
            <p className="text-2xl font-extrabold text-gray-900">SILKY SMOOTH FINISH</p>
          </div>
        </div>
      </div>
    </section>

      </>
    );
};

export default Cancel;
