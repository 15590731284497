import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Calendar from './pages/Calendar';
import ThankYou from './pages/ThankYou';
import Cancel from './pages/Cancel';
import Footer from './components/Footer'; 
import TermsAndConditions from './pages/Terms';
import PrivacyPolicy from './pages/Privacy';


function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Calendar />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
