import React from "react";

import DonnaL from '../assets/images/DonnaL.png';
import KyleV from '../assets/images/KyleV.jpg';
import KathyF from '../assets/images/KathyF.png';

const TestimonialSection = () => {
    const testimonials = [
      {
        name: "Donna L",
        quote: "So excited to put this on my desk!",
        image: DonnaL,
        position: "Boise",
      },
      {
        name: "Kyle V",
        quote: "I have the 2024 Calendar and cannot wait to get the 2025 Calendar",
        image: KyleV,
        position: "Couer D' Alene",
      },
      {
        name: "Kathy F",
        quote: "I order two, one for a christmas present and one for myself.",
        image: KathyF,
        position: "Meridian",
      },
    ];
  
    return (
      <div className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-8">
            What Our Customers are saying..
          </h2>
          <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 text-center"
              >
                <div className="flex justify-center mb-4">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                </div>
                <p className="italic text-gray-600 mb-4">"{testimonial.quote}"</p>
                <h3 className="text-lg font-bold text-gray-900">{testimonial.name}</h3>
                <p className="text-sm text-gray-500">{testimonial.position}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default TestimonialSection;